import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import PassgnLogo from '../logo-passgn-192.png';

const TopNavbar = () => {
  return (
    <Navbar bg="light" expand="lg" sticky='top'>
      <Container>
        <Navbar.Brand
          href="https://passgn.com"
          className='d-flex align-items-center justify-content-center w-100 fw-bold'
        >
          <img
            style={{ width: '32px' }}
            src={PassgnLogo} alt="Password Generator Logo"
            className="me-2"
          />
          PASSGN.COM
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> 
            <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            navbarScroll
          >
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

export default TopNavbar;