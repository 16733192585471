import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";

const Item = (props) => {
  const [show, setShow] = useState(false);

  const { question, answer } = props;
  return (
    <div
      style={{ cursor: "pointer" }}
      className="text-decoration-none"
      onClick={() => setShow(!show)}
    >
      <div className="bg-white border border-2 rounded-3 my-3 p-3" itemScope itemProp="mainEntity" itemType="schema/Question">
        <h3 className="m-0" itemProp="name">
          {question}
        </h3>
        <div className="overflow-hidden" style={{ height: show ? 'auto' : '1px' }} itemScope itemProp="acceptedAnswer" itemType="schema/Answer">
          <div className="border-top mt-2 pt-2" itemProp="text">
            {answer}
          </div>
        </div>
      </div>
    </div>
  )
}

const FAQ = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="align-items-center my-3">
        <Col xxl={6} xl={8} lg={9} className="mx-auto">
          <div className="text-center">
            <h2>Passgn Password Generator FAQs</h2>
            <p>Recently asked questions about Passgn random password generator</p>
          </div>
          <div className="pt-3">
            <Item
              question="Is the Passgn safe enough?"
              answer={<p>Of course it is safe. Passgn creates unique passwords for you using completely secure algorithms. These passwords are unique and will not be stored in any way by Passgn and will not even be made available to the public.</p>}
            />

            <Item
              question="Why should use random password generator?"
              answer={<p>Randomly generated passwords are typically stronger than passwords that are easy to guess or remember. This makes it harder for hackers to gain access to your accounts.</p>}
            />

            <Item
              question="What is the best password generator?"
              answer={
                <div>
                  <p>
                    The best password generator should have the following features:
                  </p>

                  <ol>
                    <li>
                      <p>
                        Randomness: The passwords generated should be completely random and not based on any pattern or algorithm.
                      </p>
                    </li>
                    <li>
                      <p>
                        Length: The passwords generated should be at least 12 characters long, and preferably longer.
                      </p>
                    </li>
                    <li>
                      <p>
                        Complexity: The passwords generated should include a mix of uppercase and lowercase letters, numbers, and special characters.
                      </p>
                    </li>
                    <li>
                      <p>
                        Avoidance of common words: The passwords generated should not include common words or phrases that could be easily guessed.
                      </p>
                    </li>
                    <li>
                      <p>
                        No personal information: The passwords generated should not include any personal information such as your name, date of birth, or address.
                      </p>
                    </li>
                    <li>
                      <p>
                        Encryption: The password generator should use strong encryption to protect the passwords it generates.
                      </p>
                    </li>
                    <li>
                      <p>
                        No storage of passwords: The password generator should not store the passwords it generates, as this could make them vulnerable to theft.
                      </p>
                    </li>
                    <li>
                      <p>
                        Availability: The password generator should be easily accessible and available to use whenever you need to create a new password.
                      </p>
                    </li>
                  </ol>
                </div>
              }
            />

            <Item
              question="What makes a password safe?"
              answer={<p>A safe password is one that is difficult for others to guess or crack. This is achieved by incorporating the features listed above in a password, such as randomness, length, complexity, avoidance of common words, and no personal information. Additionally, using unique passwords for each account and changing them regularly can also increase security. It is also important to use two-factor authentication where possible to add an extra layer of protection.</p>}
            />

            <Item
              question="Do I need a unique password for every account?"
              answer={<p>Yes, it is highly recommended to use a unique password for every account you have. Using the same password for multiple accounts can make you vulnerable to hacking and identity theft. If one account is compromised, all of your accounts that share that password could also be at risk. It may be challenging to remember multiple passwords, but using a password manager can help simplify the process.</p>}
            />

            <Item
              question="Passphrase vs Password"
              answer={<p>A passphrase is a sequence of words or other text used as a password, whereas a password is a secret code or combination of characters used to gain access to something. Passphrases are generally longer and more complex than passwords, making them more secure. However, both passphrases and passwords can be secure if they are strong and not easily guessable. It is important to use a combination of upper and lowercase letters, numbers, and symbols in your passphrase or password to make it more difficult to crack.</p>}
            />
          </div>
        </Col>
      </Row>
    </Container >
  )
}

export default FAQ;