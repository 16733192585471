import TopNavbar from './components/navbar';
import PasswordForm from './components/password-form';
import './App.scss';
import MainContent from './components/main-content';
import FAQ from './components/faq';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
      <TopNavbar />

      <PasswordForm />

      <MainContent />

      <FAQ />

      <Footer />
    </div>
  );
}

export default App;
