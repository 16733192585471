const Footer = () => {
  return (
    <div className="mt-5 p-3 text-center bg-light">
      <p className="m-0">
        © 2023 <a href="https://passgn.com">Passgn</a>. All rights reserved.
      </p>
    </div>
  )
}

export default Footer;