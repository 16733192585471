import { Col, Container, Row } from "react-bootstrap";
import PasswordImpoertanceImg from '../../src/images/password-importance-passgn.png';
import UseStrongPasswordImg from '../../src/images/use-strong-password.png';
import RandomPasswordGeneratorImg from '../../src/images/random-password-generator.png';
import PassgnHowWorksImg from '../../src/images/how-passgn-works.gif';

const MainContent = () => {
  return (
    <Container className="mt-5 pt-5">
      <Row className="g-3">
        <Col lg={8}>
          <h2>
            Secure Password Importance
          </h2>
          <p>
            A password is a secret combination of characters that is used to verify the identity of a user. Passwords are typically used in conjunction with a username to gain access to a computer system, website, or other online service.
          </p>
          <p>
            Passwords are important because they help to protect your personal information and accounts from unauthorized access. If someone were to guess your password, they would be able to log into your account and potentially steal your personal information, such as your credit card number or social security number.
          </p>
        </Col>
        <Col lg={4}>
          <img className="img-fluid border rounded-3" src={PasswordImpoertanceImg} alt="Password importance" />
        </Col>
      </Row>

      <Row className="mt-5 pt-5 g-3">
        <Col lg={6}>
          <div className="h-100 d-flex align-items-center">
            <img className="img-fluid border rounded-3" src={UseStrongPasswordImg} alt="Using strong password" />
          </div>
        </Col>
        <Col lg={6}>
          <h2>
            Why use strong passwords?
          </h2>
          <p>
            Strong passwords are important because they help to protect your personal information and accounts from unauthorized access. If someone were to guess your password, they would be able to log into your account and potentially steal your personal information, such as your credit card number or social security number.
          </p>

          <ul>
            <li>
              <p>
                Strong passwords are more difficult to guess. Hackers use a variety of techniques to try to guess passwords, such as using brute force attacks or dictionary attacks. A strong password will make it more difficult for them to succeed.
              </p>
            </li>
            <li>
              <p>
                Strong passwords help to protect your accounts from malware. Malware can be used to steal passwords, so it's important to use strong passwords to make it more difficult for malware to succeed.
              </p>
            </li>
            <li>
              <p>
                Strong passwords help to protect your accounts from phishing attacks. Phishing attacks are emails or websites that try to trick you into giving away your personal information. A strong password will make it more difficult for phishers to succeed.
              </p>
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="mt-5 pt-5 g-3">
        <Col lg={6}>
          <h2>
            Using Random password generator
          </h2>
          <p>
            Random passwords are a great way to create strong passwords that are difficult to guess. They are typically generated by a random password generator, which uses a variety of algorithms to create a unique and secure password.
          </p>

          <ul>
            <li>
              <p>
                They are very difficult to guess. Hackers typically use brute force attacks or dictionary attacks to try to guess passwords. Random passwords are much more difficult to guess using these methods.
              </p>
            </li>
            <li>
              <p>
                They are easy to remember. Random passwords are often generated as a long string of random characters, which can be difficult to remember. However, there are a number of password managers that can help you store and manage your random passwords.
              </p>
            </li>
            <li>
              <p>
                They are unique. Random passwords are typically generated as a unique string of characters, which means that you can use them for multiple accounts without having to worry about reusing passwords.
              </p>
            </li>
            <li>
              <p>
                If you are looking for a way to create strong and secure passwords, then using random passwords is a great option. They are difficult to guess, easy to remember, and unique, which makes them a great way to protect your online accounts.
              </p>
            </li>
          </ul>
        </Col>
        <Col lg={6}>
          <div className="h-100 d-flex align-items-center">
            <img className="img-fluid border rounded-3" src={RandomPasswordGeneratorImg} alt="Random password generator" />
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-5 g-3">
        <Col lg={6}>
          <img className="img-fluid border rounded-3" src={PassgnHowWorksImg} alt="Random password generator instruction" />
        </Col>
        <Col lg={6}>
          <h3>Passgn.com random password generator instruction</h3>
          <ol>
            <li>
              <p>
                Select the length of your password.
              </p>
            </li>
            <li>
              <p>
                Choose whether you want to include upper and lowercase letters, numbers, and symbols in your password.
              </p>
            </li>
            <li>
              <p>
                Click on the "Generate" button.
              </p>
            </li>
            <li>
              <p>
                The random password generator will generate a random password for you.
              </p>
            </li>
            <li>
              <p>
                Copy the password and paste it into the password field on the website or app where you are creating your account.
              </p>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  )
}

export default MainContent;