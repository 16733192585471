import { useState, useEffect } from "react";
import { Container, Badge, ToastContainer, Toast, Button, Form, InputGroup, Row, Col } from "react-bootstrap";

const PasswordForm = () => {
  const [length, setLength] = useState(13);
  const [numbers, setNumbers] = useState(true);
  const [uppercase, setUppercase] = useState(true);
  const [lowercase, setLowercase] = useState(true);
  const [symbols, setSymbols] = useState(true);

  const [password, setPassword] = useState('');
  const [strength, setStrength] = useState();

  const [toast, setToast] = useState(false);


  const activeOptions = () => {
    return numbers + uppercase + lowercase + symbols > 1 ? true : false;
  }

  const generate = (l = length) => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbersChars = "0123456789";
    const symbolsChars = "!@#$%^&*+";

    const charset = (numbers ? numbersChars : '') + (uppercase ? uppercaseChars : '') + (lowercase ? lowercaseChars : '') + (symbols ? symbolsChars : '')

    let retVal = "";

    for (let i = 0; i < l; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setPassword(retVal);
  }

  useEffect(() => {
    generate();
  }, [numbers, uppercase, lowercase, symbols, length])

  useEffect(() => {
    if (length >= 1 && length <= 6) {
      setStrength('weak');
    } else if (length >= 7 && length <= 9) {
      setStrength('medium');
    } else if (length >= 10 && length <= 13) {
      setStrength('strong');
    } else if (length >= 14 && length <= 22) {
      setStrength('stronger');
    } else if (length >= 23 && length <= 64) {
      setStrength('legendary');
    }
  }, [length]);

  return (
    <Container>
      <Row className="align-items-center my-3">
        <Col xxl={6} xl={8} lg={9} className="mx-auto">
          <div className="text-center">
            <h1
              style={{ fontWeight: '900' }}
            >
              Use strong random passwords with the new passgn password generator
            </h1>
            <p style={{ fontWeight: '500' }}>
              <a href="https://passgn.com">Passgn</a> is a useful online password generator.
              With this <a href="https://passgn.com">random password generator</a> you can easily determine the length of your password and use different character types.
            </p>
          </div>
          <div className="mt-3 bg-white rounded-4 p-3 shadow-sm">
            <Form onSubmit={e => e.preventDefault()}>
              <InputGroup>
                <Form.Control
                  type="text"
                  className="form-control p-2 border-0"
                  id="password"
                  style={{
                    fontSize: '1.8rem',
                    fontFamily: 'Red Hat Mono, monospace',
                    letterSpacing: '.1rem',
                    paddingRight: '20px'
                  }}
                  value={password}
                  readOnly
                  onClick={e => e.target.select()}
                />

                <Button
                  className="d-flex align-items-center px-3"
                  variant="light"
                  onClick={() => {
                    navigator.clipboard.writeText(password)
                    setToast(true);
                  }}
                >

                  <i
                    style={{ fontSize: '1.8rem' }}
                    className="icon-document-copy"
                  ></i>
                </Button>
                <Button
                  className="d-flex align-items-center px-3"
                  variant="light"
                  onClick={() => generate()}
                >
                  <i className="icon-refresh"></i>
                </Button>
              </InputGroup>
            </Form>
          </div>
          <div className="mt-3 bg-white rounded-4 p-3 shadow-sm">
            <Row>
              <Col>
                <div className="d-block d-md-flex align-items-center">
                  <Form.Label className="m-0" style={{ minWidth: '99px', fontWeight: "500" }}>
                    Length:
                    <span className="ms-1 fw-bold"> {length} </span>
                  </Form.Label>
                  <Form.Label
                    className="m-0 mx-2"
                    style={{ minWidth: '90px', fontWeight: "500" }}>
                    <h5 className="m-0 d-flex align-items-center justify-content-center">
                      <Badge
                        style={{ minWidth: '85px' }}
                        className="p-2 fw-normal"
                        bg={
                          (strength === 'weak' && 'danger') ||
                          (strength === "medium" && 'warning') ||
                          (strength === 'strong' && 'success') ||
                          (strength === 'stronger' && 'success') ||
                          (strength === 'legendary' && 'dark')
                        }>
                        {strength}
                      </Badge>
                    </h5>
                  </Form.Label>
                  <Form.Range
                    min={1}
                    max={64}
                    defaultValue={length}
                    className="mt-4 mt-md-0"
                    onChange={e => setLength(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Form className="mt-4 d-block d-md-flex justify-content-between align-items-center">
              <Form.Check
                type="switch"
                id="lower"
                label="abc"
                inline
                checked={lowercase}
                onChange={e => setLowercase(activeOptions() ? !lowercase : true)}
              />

              <Form.Check
                type="switch"
                id="upper"
                label="ABC"
                inline
                checked={uppercase}
                onChange={e => setUppercase(activeOptions() ? !uppercase : true)}
              />

              <Form.Check
                type="switch"
                id="number"
                inline
                label="123"
                checked={numbers}
                onChange={e => setNumbers(activeOptions() ? !numbers : true)}
              />

              <Form.Check
                type="switch"
                id="symbols"
                label="@#$"
                inline
                checked={symbols}
                onChange={e => setSymbols(activeOptions() ? !symbols : true)}
              />

            </Form>
            <Row className="mt-4 g-3">
              <Col>
                <Button
                  className="w-100  d-flex align-items-center justify-content-center"
                  variant="primary"
                  type="submit"
                  onClick={() => generate()}
                >
                  <i className="icon-refresh me-2"></i>
                  Generate
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 d-flex align-items-center justify-content-center"
                  variant="secondary"
                  type="submit"
                  onClick={() => {
                    navigator.clipboard.writeText(password)
                    setToast(true);
                  }}
                >
                  <i className="icon-document-copy me-2"></i>
                  Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ToastContainer position="bottom-center">
        <Toast
          onClose={() => setToast(false)}
          show={toast}
          delay={3000}
          autohide
          className="d-inline-block m-1"
          bg="light"
        >

          <Toast.Header>
            <img
              style={{ width: '16px' }}
              src="../logo-passgn-192.png"
              className="me-2"
              alt="Logo"
            />
            <strong className="me-auto">Password Generator</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>
            Copied.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container >
  )
}

export default PasswordForm;